import React, { FC } from 'react';
import { User as TUser } from 't2-graphql-types';
import cx from 'classnames';
import Typo from '../Typography/Typo';
import { formatDate } from '../../../utils/formatDate';
import DotSeparator from '../CommentsSection/Comment/DotSeparator';
import ThreeDotsIcon from '../Icons/ThreeDotsIcon';
import { PostCardHeaderUserProfile } from './PostCardHeaderUserProfile';
import { getDropdownMenuButtons } from './PostCardFooter';
import Dropdown from '../Dropdown/Dropdown';
import { CardDropdownMenu } from '../CardDropdownMenu/CardDropdownMenu';
import { PinIcon } from '../Icons/PinIcon';
import { TArticleCardProps } from '../ArticleCard/ArticleCard';
import { UnstyledTooltip } from '../Tooltip/UnstyledTooltip';
import { PROFILE_CARD_HOVER_DELAY } from '../ProfileCard/constants';
import { T2ProfileCardContainer } from '../ProfileCard/T2ProfileCardContainer';

type TPostCardHeaderProps = {
  article: TArticleCardProps & { author: TUser };
  canManagePinnedArticles?: boolean;
  isPinningEnabled?: boolean;
  onPinClick?: () => void;
  forceMobileView?: boolean;
  showTerritory?: boolean;
  territoryName?: string | null;
};

export const PostCardHeader: FC<TPostCardHeaderProps> = ({
  article,
  canManagePinnedArticles,
  isPinningEnabled,
  onPinClick,
  forceMobileView = false,
  showTerritory,
  territoryName,
}) => {
  const menuButtons = getDropdownMenuButtons(article?.territoryPinnedAt!, onPinClick);
  return (
    <div
      className={cx('flex flex-wrap mt-2 items-center order-2', {
        'md:mt-4 md:order-1 md:justify-between': !forceMobileView,
      })}
    >
      <div className="z-[1]">
        <UnstyledTooltip
          direction="right"
          floatingUIHover={PROFILE_CARD_HOVER_DELAY}
          trigger={
            <PostCardHeaderUserProfile
              authorLabel={article?.authorLabel}
              authorProfileURL={article?.authorProfileURL}
              forceMobileView={forceMobileView}
              profileImage={article?.authorProfileImage}
            />
          }
        >
          <T2ProfileCardContainer id={article?.author?.id as string} />
        </UnstyledTooltip>
      </div>
      <DotSeparator
        className={cx('!block mx-2 bg-t2-neutral-grey03', {
          'md:!hidden': !forceMobileView,
        })}
      />
      <Typo
        variant="small-p"
        className={cx('!text-xs text-t2-neutral-grey05 whitespace-nowrap', {
          'md:!text-sm': !forceMobileView,
        })}
      >
        {formatDate(article?.createdAt || article?.publishedAt)}
      </Typo>
      <div
        className={cx('flex items-center ml-auto', {
          'md:hidden': !forceMobileView,
        })}
      >
        {isPinningEnabled && article?.territoryPinnedAt && (
          <PinIcon className="w-3 h-3 stroke-t2-primary-brandColor fill-t2-primary-brandColor -z-10 mr-2" />
        )}
        {canManagePinnedArticles && (
          <div className="z-20 h-5">
            <Dropdown
              label={<ThreeDotsIcon />}
              menu={<CardDropdownMenu buttons={menuButtons} />}
              position="right"
              menuContainerClassName="bottom-[35px] border border-t2-neutral-grey02 shadow-hoverShadow"
            />
          </div>
        )}
      </div>
      {showTerritory && territoryName && (
        <Typo
          className={cx('self-end py-2 text-sm', {
            'md:hidden': !forceMobileView,
          })}
        >{`t/${territoryName}`}</Typo>
      )}
    </div>
  );
};
