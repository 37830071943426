import React, { FC } from 'react';
import cx from 'classnames';
import { PostCardImage } from './PostCardImage';
import { PostCardContent } from './PostCardContent';
import { PostCardHeader } from './PostCardHeader';
import { PostCardFooter } from './PostCardFooter';
import { BaseCard } from '../BaseCard/BaseCard';
import { EyeIcon } from '../Icons/EyeIcon';
import { PostCardImageInfoTile } from './PostCardImageInfoTile';
import { TPostCardProps } from './types';

export const PostCard: FC<TPostCardProps> = ({
  article,
  canManagePinnedArticles,
  isPinningEnabled,
  onPinClick,
  forceMobileView = false,
  onPreviewClick,
  showTerritory,
  onClick,
}) => {
  const territoryName = article?.territory?.name;

  return (
    <BaseCard url={article.url} cardType="post" forceMobileView={forceMobileView} onClick={onClick}>
      {onPreviewClick && !forceMobileView && (
        <div className={cx('absolute top-[16px] right-4 gap-2 md:flex', {})}>
          <button onClick={onPreviewClick} className="invisible group-hover:visible hover:opacity-95 z-50">
            <PostCardImageInfoTile backgroundColor="white">
              <EyeIcon /> Preview
            </PostCardImageInfoTile>
          </button>
        </div>
      )}
      <PostCardImage article={article} forceMobileView={forceMobileView} />
      <div
        className={cx('flex flex-col w-full justify-between h-full', {
          'md:px-2 w-full md:self-start': !forceMobileView,
        })}
      >
        <PostCardHeader
          // @ts-ignore
          article={article}
          isPinningEnabled={isPinningEnabled}
          canManagePinnedArticles={canManagePinnedArticles}
          onPinClick={onPinClick}
          forceMobileView={forceMobileView}
          showTerritory={showTerritory}
          territoryName={territoryName}
        />
        <PostCardContent article={article} forceMobileView={forceMobileView} />
      </div>
      <div
        className={cx('flex h-full', {
          'md:w-full md:mt-auto md:px-2': !forceMobileView,
        })}
      >
        <PostCardFooter
          article={article}
          canManagePinnedArticles={canManagePinnedArticles}
          isPinningEnabled={isPinningEnabled}
          // @ts-ignore
          onPinClick={onPinClick}
          forceMobileView={forceMobileView}
          showTerritory={showTerritory}
          territoryName={territoryName}
        />
      </div>
    </BaseCard>
  );
};
