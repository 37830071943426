import { Article as TArticle, UserArticleRelation } from 't2-graphql-types';
import { TArticleCardProps } from '../ArticleCard';
import getUserDisplayName from '../../../../utils/getUserDisplayName';
import { getPromptPageUrl } from '../../../services/pagesUrl/getPromptPageUrl';
import { getArticlePageUrl } from '../../../services/pagesUrl/getArticlePageUrl';
import { getUserPageUrl } from '../../../services/pagesUrl/getUserPageUrl';
import { TArticleDataMapperResult } from '../types';

export const READING_HISTORY_THRESHOLD = (article: { userRelations?: Array<UserArticleRelation> | null }) =>
  !!article?.userRelations?.find(
    (userRelation) => userRelation?.relationType === 'read' && Number(userRelation?.percentReadTime) > 0.5,
  );

export const articleDataMapperFactory =
  (isTerritoryMembersOnly = false) =>
  (article: TArticle): TArticleDataMapperResult => {
    const url =
      article.type === 'PROMPT' ? getPromptPageUrl(article.slug || '') : getArticlePageUrl(article.slug || '');
    const authorLabel = getUserDisplayName(article.author || undefined);
    const authorProfileURL = getUserPageUrl(article.author?.username || '');
    const authorProfileImage = article.author?.profileImage;
    const totalTimePointsAccumulated = article?.totalTimePointsAccumulated;
    const locked = isTerritoryMembersOnly ? false : article.access === 'territoryMembers';
    const postsCount = article?.childArticlesCount;
    const hasRead = READING_HISTORY_THRESHOLD(article);

    return {
      ...article,
      locked,
      authorProfileURL,
      authorLabel,
      authorProfileImage,
      url,
      totalTimePointsAccumulated,
      postsCount,
      hasRead,
    };
  };

export const getArticlesCardData = (
  articles = [] as TArticle[],
  isTerritoryMembersOnly = false,
): TArticleCardProps[] => {
  // TODO: change article.readTime to number (API)
  const articleDataMapper = articleDataMapperFactory(isTerritoryMembersOnly);
  // @ts-ignore
  return articles.filter(Boolean).map(articleDataMapper);
};
