type TProps = {
  className?: string;
};

export const EyeIcon = ({ className }: TProps) => (
  <svg className={className} width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
      <path d="M.667 8S3.334 2.667 8 2.667C12.667 2.667 15.334 8 15.334 8S12.667 13.334 8 13.334C3.334 13.334.667 8 .667 8Z" />
      <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
    </g>
  </svg>
);
