import React, { FC } from 'react';
import cx from 'classnames';
import Typo from '../Typography/Typo';
import { getMultilineEllipsisStyle } from '../PromptCard/utils/getMultilineEllipsisStyle';
import { TArticleCardProps } from '../ArticleCard/ArticleCard';

type TPostCardContentProps = {
  article: TArticleCardProps;
  forceMobileView?: boolean;
};

export const PostCardContent: FC<TPostCardContentProps> = ({ article, forceMobileView }) => (
  <div
    className={cx('flex flex-col gap-3 order-1', {
      'md:mt-3 md:order-2': !forceMobileView,
    })}
  >
    <Typo
      variant="small-p"
      className={cx('mr-12 mt-1 !text-sm text-t2-shades-black multiline-ellipsis', {
        'md:h-[56px] md:mr-0 md:!text-lg md:!font-semibold': !forceMobileView,
        'md:!text-base': forceMobileView,
      })}
      style={getMultilineEllipsisStyle(2)}
    >
      {article?.title}
    </Typo>
    <div
      className={cx('hidden', {
        'md:block': !forceMobileView,
      })}
    >
      <Typo
        variant="small-p"
        className="!text-sm text-t2-neutral-grey06 multiline-ellipsis break-words"
        style={getMultilineEllipsisStyle(3)}
      >
        {article?.shortDescription}
      </Typo>
    </div>
  </div>
);
