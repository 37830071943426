export const ARTICLE_CARD_CLASSNAMES = {
  container:
    'relative h-full mb-4 md:mb-6 @2xl:px-6 pb-5 @2xl:py-6 bg-white-900 flex flex-col gap-y-5 @2xl:flex-row gap-x-2 lg:gap-x-5 rounded-xl z-0 shadow-staticShadow justify-between',
  content: 'flex flex-1 px-7 @2xl:pl-0 flex-col gap-y-4 @2xl:gap-y-6 min-w-0 order-2 @2xl:order-1',
  authorSectionContainer: 'flex flex-row items-center gap-x-1 cursor-pointer relative flex-wrap',
  textColumn: 'flex flex-col gap-y-4 break-words',
};

export const enum ArticleCardPurpose {
  latestPosts = 'latest_posts',
  userReadingHistory = 'user_reading_history',
  userDrafts = 'user_drafts',
  userPublished = 'user_published',
  promptPosts = 'prompt_posts',
  recommendedPosts = 'recommended_posts',
  homepagePosts = 'homepage_posts',
}

export const DEFAULT_ARTICLE_IMAGE = '/images/post-image-placeholder.webp';
